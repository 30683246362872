<mat-chip-grid #chipList>
  <ng-container *ngFor="let option of _dataView.selectedOptions; trackBy: trackById">
    <mat-chip-option [removable]="removable && !_disabled" (removed)="onOptionRemove(option)">
      <ng-container
        [ngTemplateOutlet]="triggerTemplate?.template ?? defaultTriggerTemplate"
        [ngTemplateOutletContext]="{ $implicit: option }"
      ></ng-container>
      <fa-icon matChipRemove *ngIf="removable && !_disabled" icon="minus-circle"></fa-icon>
    </mat-chip-option>
  </ng-container>

  <input
    type="text"
    #input
    class="input"
    [placeholder]="_placeholder"
    [formControl]="control"
    [matAutocomplete]="modelAutocomplete"
    [matAutocompleteDisabled]="_disabled"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    [id]="id + '-input'"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
    [disabled]="disabled"
  />
</mat-chip-grid>
<mat-autocomplete
  #modelAutocomplete="matAutocomplete"
  (optionSelected)="onOptionSelected($event.option.value)"
  class="x-data-autocomplete-panel"
>
  <mat-option *ngFor="let option of _dataView.options; trackBy: trackById" [value]="option">
    <ng-container
      [ngTemplateOutlet]="optionTemplate?.template ?? defaultOptionTemplate"
      [ngTemplateOutletContext]="{ $implicit: option }"
    ></ng-container>
  </mat-option>
</mat-autocomplete>

<ng-template #defaultOptionTemplate let-option>
  <span class="default-option mat-body-2">{{ option.display }}</span>
</ng-template>

<ng-template #defaultTriggerTemplate let-option>
  <span>{{ option.display }}</span>
</ng-template>

<ng-container *ngIf="fetchState$ | async as state" [xOperation]="state">
  <ng-container *xOperationError="let error">
    <fa-icon icon="circle-exclamation" [matTooltip]="error.title"></fa-icon>
  </ng-container>
  <ng-container *xOperationLoading>
    <fa-icon icon="spinner" matTooltip="Fetching list..."></fa-icon>
  </ng-container>
</ng-container>

<ng-container *ngIf="resolveState$ | async as state" [xOperation]="state">
  <ng-container *xOperationError="let error">
    <fa-icon icon="circle-exclamation" [matTooltip]="error.title"></fa-icon>
  </ng-container>
  <ng-container *xOperationLoading>
    <fa-icon icon="spinner" matTooltip="Resolving items..."></fa-icon>
  </ng-container>
</ng-container>
