import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface DataFilterControlOptions {
  modelViewTransform?: (modelValue: any) => any;
  viewModelTransform?: (modelValue: any) => any;
}

export class DataFilterControl<
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  } = any,
> extends FormGroup<TControl> {
  private _lastValue: any = null;
  private _suppressChanges = false;

  changes$: Observable<any>;

  constructor(
    controls: TControl,
    private options?: DataFilterControlOptions,
  ) {
    super(controls);
    this.changes$ = this.valueChanges.pipe(
      map((value) => {
        if (!this._suppressChanges) {
          this._lastValue = this.options?.viewModelTransform
            ? this.options.viewModelTransform(value)
            : value;
        }
        return this._lastValue;
      }),
    );
  }

  resetValueFromCurrentFilter(value: any, emitEvent = false) {
    if (value === this._lastValue) return;
    this._lastValue = value;

    const filter = this.options?.modelViewTransform
      ? this.options.modelViewTransform(value)
      : value;

    const newValue = Object.entries(this.controls).reduce((acc, [key, control]) => {
      acc[key] = Object.prototype.hasOwnProperty.call(filter, key) ? filter[key] : null;
      return acc;
    }, {} as any);

    this.reset(newValue, { emitEvent });
  }

  override reset(value: any, options: { emitEvent: boolean } = { emitEvent: true }): void {
    if (!options.emitEvent) {
      this._suppressChanges = true;
    }
    super.reset(value, options);
    Promise.resolve().then(() => {
      this._suppressChanges = false;
    });
  }
}
